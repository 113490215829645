import { Box, Center, Image, Link, Text } from "@chakra-ui/react";

import news1 from "../assets/news/news1.jpg";
import { DynamicTitle } from "../utils/DynamicTitle";

export const NewsPage = () => {
  DynamicTitle("News | Kapten Mulyono")

  return (
    <Box>
      <Center>
        <Image maxH={"500px"} src={news1} />
      </Center>
      <br />
      <Text>
        Peresmian penggantian nama Bandara Kapten Mulyono dari Bandara Kuala
        Pembuang di Kabupaten Seruyan, Kalimantan Tengah
        <br />
        <br />
        Pada 10 Nov 2022, Bupati Yulhaidir mencanangkan kembali nama Bandara
        Kapten Mulyono di Kabupaten Seruyan, Kalimantan Tengah. link berita:
        <Link overflowWrap={"anywhere"} href={"https://prokalteng.co/2022/11/11/Nama-Bandara-Kuala-Pembuang-Menjadi-Bandara-Kapten-Mulyono/"} color={"blue"}>
          {" "}
          https://prokalteng.co/2022/11/11/Nama-Bandara-Kuala-Pembuang-Menjadi-Bandara-Kapten-Mulyono/
        </Link>
      </Text>
    </Box>
  );
};
