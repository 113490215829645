import { Box, Button, Flex, Link, Text } from "@chakra-ui/react";
import { DynamicTitle } from "../utils/DynamicTitle";

export const ComicPage = () => {
  DynamicTitle("Comics | Kapten Mulyono")
  return (
    <Box px={5} pt={2}>
      {Comicdata.map((item, index) => {
        return (
          // <ComicBar key={index} href={"komik" + item.href}>
          <ComicBar key={index} href={item.href}>
            {item.title}
          </ComicBar>
        );
      })}
    </Box>
  );
};

const ComicBar = ({ children, href = "#" }) => {
  return (
    <Box>
      <Flex
        border={"1px solid black"}
        h={"70px"}
        fontSize={{ base: "0.8rem", md: "1rem" }}
        my={2}
        _hover={{ textDecoration: "none", bg: "blackAlpha.50" }}
        align={"center"}
        px={"10px"}
        justify={"space-between"}
        rounded={"md"}
      >
        <Text textAlign={"center"}>{children}</Text>
        <Button
          as={Link}
          fontSize={"0.8rem"}
          href={href}
          target={"_blank"}
          colorScheme={"red"}
          _hover={{textDecor: "none", bg: "#C53030"}}
        >
          CLICK DISINI
        </Button>
      </Flex>
    </Box>
  );
};

const Comicdata = [
  {
    title: "JEJAK PERJUANGAN KAPTEN MULYONO DI KALIMANTAN (1946 - 1948)",
    // href: "/jejak-perjuangan-kapten-mulyono-di-kalimantan",
    href:
      process.env.PUBLIC_URL +
      "/komik/jejak-perjuangan-kapten-mulyono-di-kalimantan.html",
    body: "Komik ini dibuat tahun 2022 dan diluncurkan bertepatan dengan hari Pahlawan, yakni 10 November, dan Pencanangan kembali Bandara Kapten Mulyono menggantikan Bandara Kuala Pembuang di Kabupaten Seruyan, Provinsi Kalimantan Tengah. Mengapa komik? Pemilihan komik sebagai media baca bertujuan agar lebih mudah dicerna dan asyik dibaca oleh segala kalangan, terutama kaum muda, sehingga generasi muda dapat mengenal lebih jauh tentang salah satu putra terbaik Indonesia yang dalam diam telah berjuang keras demi cinta kepada tanah air Indonesia.",
  },
];
