import React from "react";

import { Box, Flex, Grid } from "@chakra-ui/react";

import { CardComp } from "../components/Card";
import { Footer } from "../components/Footer";
import { HomeHeader } from "../components/HomeHeader";

import about from "../assets/about/kapten-mulyono.jpeg";
import history from "../assets/history/history1.jpeg";
import comic from "../assets/comic-cover.jpg";
import moh from "../assets/moh/MOH1.jpg";
import news from "../assets/news/news1.jpg";
import family from "../assets/family/family.jpeg";
import pdf from "../assets/PDF/PDF-thumbnail.png";
import documentation from "../assets/documentation/Dokumentasi2.jpeg";

import bgPic from "../assets/bgPic.png";

export const HomePage = () => {
  return (
    <Box bg={"#ededed"}>
      <Flex
        flexDir={"column"}
        minH={"100vh"}
        backgroundImage={bgPic}
        backgroundPosition="center"
        backgroundAttachment={"fixed"}
        backgroundRepeat="no-repeat"
        bgSize={"80vw"}
      >
        <HomeHeader />
        <CardLayout />
        <Footer />
      </Flex>
    </Box>
  );
};

const CardLayout = () => {
  return (
    <Box pt={{ base: "120px", md: "320px" }} pb={"50px"}>
      {/* // <Box pt={{ base: "120px", md: "35vh" }} pb={"50px"}> */}
      <Box>
        <Grid
          templateColumns={{
            base: "none",
            md: "repeat(auto-fit, 350px)",
            "2xl": "repeat(auto-fit, 500px)",
          }}
          justifyContent={"center"}
          gap={"3vw"}
          rowGap={"5vw"}
        >
          {cardData.map((item, index) => {
            return (
              <Box h={"fit-content"} key={index}>
                <CardComp
                  sizeV={{ base: "300px", md: "350px", "2xl": "500px" }}
                  title={item.title}
                  input={item.input}
                  pic={item.pic}
                  href={item.href}
                />
              </Box>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

const cardData = [
  {
    title: "TENTANG KAPTEN MULYONO",
    href: "/tentang-kapten-mulyono",
    input: "SIAPAKAH KAPTEN MULYONO?",
    pic: about,
  },
  {
    title: "SEJARAH KAPTEN MULYONO",
    href: "/sejarah-kapten-mulyono",
    input:
      "Mengenal lebih jauh sosok pejuang yang menjadi bagian dari misi berdirinya Badan Intelijen Negara Republik Indonesia",
    pic: history,
  },
  {
    title: "KOMIK KAPTEN MULYONO",
    href: "/komik-kapten-mulyono",
    input: "Jejak Perjuangan Kapten Mulyono di Kalimantan (1946 - 1948)",
    pic: comic,
  },
  {
    title: "TANDA PENGHARGAAN",
    href: "/tanda-penghargaan",
    input: "Tanda Penghargaan dari Negara Republik Indonesia",
    pic: moh,
  },
  { title: "BERITA", href: "/berita", input: "Berita terbaru", pic: news },
  {
    title: "PUTRA-PUTRI KAPTEN MULYONO",
    href: "/putra-putri-kapten-mulyono",
    input: "Daftar ahli waris Kapten Mulyono",
    pic: family,
  },
  {
    title: "SUMBER",
    href: "/sumber-kapten-mulyono",
    input: "Sumber PDF",
    pic: pdf,
  },
  {
    title: "DOKUMENTASI",
    href: "/dokumentasi-kapten-mulyono",
    input: "Kumpulan foto-foto dokumentasi Kapten Mulyono",
    pic: documentation,
  },
];
