import { Box, Center, Image, Link, Text } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import history1 from "../assets/history/history1.jpeg";
import history2 from "../assets/history/history2.png";
import { DynamicTitle } from "../utils/DynamicTitle";

const picArr = [history1, history2];

export const HistoryPage = () => {
  DynamicTitle("History | Kapten Mulyono")
  
  return (
    <Box>
      <Center zIndex={0} position={"relative"}>
        <Box bg={"#F7FAFC"} maxW={{ base: "300px", md: "600px" }}>
          <SwiperHistory />
        </Box>
        {/* <Image h={"200px"} src={history2} /> */}
      </Center>
      <Text pt={"10px"}>
        Ketika Belanda bertujuan menerapkan perpecahan di Indonesia dengan
        meresmikan berdirinya Hindia Belanda yang meliputi pulau Kalimantan,
        Sulawesi dan Indonesia Timur sebagai tantangan untuk Republik Indonesia
        yang terdiri dari pulau Jawa dan Sumatera, Mulyono dikirim sebagai
        tentara intelijen ke Kalimantan. Tugas yang diemban oleh Mulyono tidak
        mudah dan memerlukan kemampuan berpikir taktis di segala situasi.
        <br />
        <br />
        Sekelumit dari kisah perjuangan Kapten Mulyono dapat kita baca melalui
        <Link href={"/komik/jejak-perjuangan-kapten-mulyono-di-kalimantan.html"} color={"blue"} target={"_blank"}>
          {" "}
          Komik Jejak Perjuangan Kapten Mulyono Di Kalimantan (1946-1948)
        </Link>
      </Text>
    </Box>
  );
};

const SwiperHistory = () => {
  return (
    <Swiper
      spaceBetween={30}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
    >
      {picArr?.map((item, index) => {
        console.log(item);
        return (
          <SwiperSlide key={index}>
            <Center h={"100%"}>
              <Image h={{ base: "300px", md: "600px" }} src={item} />
            </Center>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
