import { useState } from "react";

import { Box, Button, Flex, Select } from "@chakra-ui/react";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import EnglishPdf from "../assets/PDF/The Adventure of Captain Mulyono-Prof Robert Cribbs-SB.pdf";
import IndonesiaPdf from "../assets/PDF/Perjuangan Kapten Mulyono-Operasi Intelijen Indonesia di Kalimantan-SB 2022.pdf";
import BRANI from "../assets/PDF/BRANI.pdf"
import KaptenM_02 from "../assets/PDF/Kapten Mulyono - 02.pdf"
import { DynamicTitle } from "../utils/DynamicTitle";


export const PdfPage = () => {
  DynamicTitle("Sources | Kapten Mulyono")

  const [pdfFile, setPdfFile] = useState(EnglishPdf);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Box>
      <Flex py={"20px"} justifyContent={"center"} gap={"10px"}>
        <Button as={"a"} colorScheme={"green"} href={pdfFile} download>
          Download
        </Button>
        <Select
          onChange={(e) => {
            setPdfFile(pdfList[+e.target.value].file);
          }}
          w={"150px"}
        >
          {pdfList.map((item, index) => {
            return (
              <option value={index} key={index}>
                {item.title}
              </option>
            );
          })}
        </Select>
      </Flex>
      <Box h={"1100px"}>
        <Worker
          workerUrl={
            "https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"
          }
        >
          <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} />
        </Worker>
      </Box>
    </Box>
  );
};

  const pdfList = [
    {
      title: "The Adventure of Captain Mulyono",
      file: EnglishPdf,
    },
    {
      title: "Perjuangan Kapten Mulyono",
      file: IndonesiaPdf,
    },
    {
      title: "BRANI", 
      file: BRANI,
    },
    {
      title: "KM - 02",
      file: KaptenM_02,
    },
  ];