import { Box, Center, Image, Link, Text } from "@chakra-ui/react";
import { useState } from "react";

import defaultpic from "../assets/default-pic.png";

export const CardComp = ({
  title = "TITLE HERE",
  input = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  sizeV = "300px",
  pic = defaultpic,
  href = "#",
}) => {
  const [overlay, setOverlay] = useState(false);

  return (
    <Link href={href} _hover={{ textDecor: "none" }}>
      <Box
        h={sizeV}
        w={sizeV}
        position={"relative"}
        onMouseEnter={() => setOverlay(true)}
        onMouseLeave={() => setOverlay(false)}
        _hover={{ transform: "scale(1.05)" }}
        transition={"0.2s"}
        cursor={"pointer"}
      >
        <Box position={"relative"} px={"60px"} w={"100%"} h={"20%"} zIndex={"1000"}>
          <Center
            border={"1px solid #989898"}
            rounded={"15px"}
            h={"100%"}
            bg={"#808080"}
          >
            <Text
              textAlign={"center"}
              fontSize={{ base: "1rem", md: "1.2rem", lg: "1.4rem" }}
              color={"white"}
            >
              {title}
            </Text>
          </Center>
        </Box>
        <Box
          position={"absolute"}
          w={"100%"}
          top={"10%"}
          h={"90%"}
          // zIndex={"-1"}
          rounded={"15px"}
          border={"1px solid #989898"}
          boxShadow={"lg"}
          bg={"white"}

        >
          <Box
            h={"100%"}
            padding={"25px"}
            pt={"70px"}
            rounded={"15px"}
            filter={overlay ? "blur(4px)" : ""}
            transition={"0.3s"}

          >
            <Center h={"70%"}>
              <Image src={pic} h={"100%"} border={"1px solid black"} />
            </Center>
            <Box
              h={"30%"}
              fontSize={{ base: "0.8rem", md: "0.9rem", "2xl": "1.3rem" }}
            >
              <Text textAlign={"center"}>{input}</Text>
            </Box>
          </Box>
          <Box
            position={"absolute"}
            top={0}
            left={0}
            w={"100%"}
            h={"100%"}
            rounded={"15px"}
            border={"1px solid black"}
            color={"white"}
            zIndex={"100"} 
            transition={"0.5s"}
            bg={"blackAlpha.700"}
            opacity={overlay ? "100%" : 0}
            _active={{ bg: "whiteAlpha.400" }}
          >
            <Box h={"100%"}>
              <Center h={"100%"} fontSize={"2rem"}>
                <Text border={"1px solid white"} w={"60%"} textAlign={"center"}>
                  BACA
                </Text>
              </Center>
            </Box>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};
