import { Box, Center } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import { NavbarComp } from "./Navbar";

import bgPic from "../assets/bgPic.png";

export const Layout = () => {
  return (
    <Box bg={"#ededed"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        minH={"100vh"}
        backgroundImage={bgPic}
        backgroundPosition="center"
        backgroundAttachment={"fixed"}
        backgroundRepeat="no-repeat"
        bgSize={"80vw"}
      >
        <NavbarComp />
        <Center
          pt={{ base: "90px", lg: "120px", "2xl": "220px" }}
          px={{ base: "20px", lg: "25vw" }}
          pb={"50px"}
        >
          <Box
            w={"1900px"}
            bg={"whiteAlpha.900"}
            px={"10px"}
            boxShadow={"lg"}
            rounded={"md"}
          >
            <Outlet />
          </Box>
        </Center>
        <Footer />
      </Box>
    </Box>
  );
};
