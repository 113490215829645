import { Box, Text, Link, Image, Center } from "@chakra-ui/react";

import kapten_mulyono from "../assets/about/kapten-mulyono.jpeg";
import kapten_mulyono3 from "../assets/about/kapten-mulyono3.jpeg";
import comic_cover from "../assets/comic-cover.jpg";
import news from "../assets/news/news2.jpg";
import news2 from "../assets/news/news3.png";
import { DynamicTitle } from "../utils/DynamicTitle";

export const AboutPage = () => {
  DynamicTitle("About | Kapten Mulyono")
  return (
    <Box>
      <Center pb={"20px"} display={"flex"} flexDir={"column"}>
        <Image maxH={"500px"} alt={"kapten-mulyono"} src={kapten_mulyono} />
        <Text>Kapten Mulyono</Text>
      </Center>
      <Text>
        Kisah perjuangan Kapten Mulyono ini bersumber dari tulisan Prof. Dr.
        Robert Cribb, seorang peneliti sejarah Indonesia terkemuka dan dosen di
        Australian National University, yang dapat dibaca di{" "}
        <Link
          color={"blue"}
          overflowWrap={"anywhere"}
          href={
            "https://www.academia.edu/28855440/The_adventures_of_Captain_Mulyono_Indonesian_intelligence_operations_in_Kalimantan_1946_1948"
          }
          target={"_blank"}
        >
          (https://www.academia.edu/28855440/The_adventures_of_Captain_Mulyono_Indonesian_intelligence_operations_in_Kalimantan_1946_1948)
        </Link>
        <br />
        <br />
        Lahir dari keluarga kelas menengah, Kapten Mulyono adalah anak dari
        seorang mantan wedana di Magetan (Jawa Timur). Dengan latar belakang
        tersebut, Mulyono dapat bersekolah di sekolah dasar berbahasa Belanda
        yang kemudian dilanjutkan ke sekolah teknik Queen Emma selama tiga
        tahun.
        <br />
        <br />
        Selepas sekolah, Mulyono mendapatkan pekerjaan di sebuah perusahaan
        Otoritas Air Provinsi Sumenep sebagai Asisten Penyelia. Namun, panggilan
        berperang melawan penjajah Belanda saat itu membuatnya bergabung dengan
        KNIL, di mana dia mempelajari banyak hal terkait menjadi prajurit
        perang. Keberanian dan kemampuan Mulyono dalam beradaptasi dengan segala
        situasi membuatnya direkrut sebagai salah satu anggota intelijen dari
        BRANI singkatan dari Badan Rahasia Negara Indonesia, cikal bakal dari
        Badan Intelijen Negara Republik Indonesia atau BIN.
        <br />
        <br />
        Kisah Kapten Mulyono dapat kita baca melalui komik{" "}
        <Link
          href={"/komik/jejak-perjuangan-kapten-mulyono-di-kalimantan.html"}
          color={"blue"}
          overflowWrap={"anywhere"}
          target={"_blank"}
        >
          Jejak Perjuangan Kapten Mulyono Di Kalimantan (1946-1948)
        </Link>
        .
        <br />
        <br />
        <Center pb={"20px"} display={"flex"} flexDir={"column"}>
          <Image
            maxH={"500px"}
            alt={"kapten-mulyono"}
            src={comic_cover}
            border={"1px solid black"}
          />
        </Center>
        Komik ini diproduksi pada tahun 2022 dan diluncurkan bertepatan dengan
        hari Pahlawan, 10 November. Mengapa komik? Pemilihan komik sebagai media
        baca bertujuan agar lebih mudah dicerna dan asyik dibaca oleh segala
        kalangan, terutama kaum muda, sehingga generasi muda dapat mengenal
        lebih jauh salah satu putra terbaik Indonesia yang dalam hening telah
        berjuang keras demi cinta kepada tanah air Indonesia.
        <br />
        <br />
        Peluncuran komik ini dilakukan bersamaan dengan pencanangan kembali
        Bandara Kapten Mulyono menggantikan Bandara Kuala Pembuang di Kabupaten
        Seruyan, Provinsi Kalimantan Tengah.
        <br />
        <br />
        Acara ini diselenggarakan dan dipimpin langsung oleh Bupati Seruyan,
        <br />
        <br />
        <Center pb={"20px"} display={"flex"} flexDir={"column"}>
          <Image
            maxH={"500px"}
            alt={"kapten-mulyono"}
            src={news2}
            border={"1px solid black"}
          />
        </Center>
        Yulhaidir. Link berita:
        <Link
          color={"blue"}
          target={"_blank"}
          overflowWrap={"anywhere"}
          href={
            "https://kalteng.antaranews.com/berita/603753/nama-bandara-kuala-pembuang-berganti-menjadi-kapten-mulyono"
          }
        >
          {" "}
          https://kalteng.antaranews.com/berita/603753/nama-bandara-kuala-pembuang-berganti-menjadi-kapten-mulyono
        </Link>
        <br />
        <br />
        <Center pb={"20px"} display={"flex"} flexDir={"column"}>
          <Image
            maxH={"500px"}
            alt={"kapten-mulyono"}
            src={news}
            border={"1px solid black"}
          />
        </Center>
        Sebelumnya, pada tahun 2018, perubahan nama ini telah diajukan dan
        diresmikan oleh bupati terdahulu, H. Sudarsono, SH. Link berita:
        <Link
          color={"blue"}
          target={"_blank"}
          href={"https://mmc.kalteng.go.id/berita/read/2377/index.html"}
          overflowWrap={"anywhere"}
        >
          {" "}
          https://mmc.kalteng.go.id/berita/read/2377/index.html
        </Link>
        <br />
        <br />
        <Center pb={"20px"} display={"flex"} flexDir={"column"}>
          <Image
            maxH={"500px"}
            alt={"kapten-mulyono3"}
            src={kapten_mulyono3}
            border={"1px solid black"}
          />
        </Center>
        Kapten Mulyono meninggal pada tanggal 15 Mei 1975,{" "}
        <Link href={"/putra-putri-kapten-mulyono"} color={"blue"} overflowWrap={"anywhere"}>
          meninggalkan 8 orang putera dan puteri
        </Link>{" "}
        dan dimakamkan di Taman Makam Pahlawan Bumi Kencana, Banjar Baru,
        Kalimantan Selatan.
      </Text>
    </Box>
  );
};
