import { Box, Center } from "@chakra-ui/react";
import { useState } from "react";

// import { WebsiteLogo } from "./Logo";
import { WebsiteLogo } from "./Logo";

export const HomeHeader = () => {
    const [blur, setBlur] = useState(false);
  
    const TrackScroll = () => {
      if (window.scrollY >= 1) {
        setBlur(true);
      } else {
        setBlur(false);
      }
    };
  
    window.addEventListener("scroll", TrackScroll);
    return (
      <Box
          bg={blur ? "blackAlpha.600" : ""}
          transition={"0.3s"}
          position={"fixed"}
          w={"100vw"}
          // h={{ base: "100px", md: blur ? "22vh" : "25vh", "2xl":  blur ? "200px" : "300px"}}
          h={{ base: "100px", md:  blur ? "200px" : "300px"}}
          backdropFilter={"blur(5px)"}
          zIndex={9999}
        >
      <Center w={"100vw"} h={"100%"}>
        <WebsiteLogo fontColor={blur ? "white" : "black"} transitionD={"0.3s"} />
      </Center>
        </Box>
    );
  };