import {
  Box,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
  Center,
} from "@chakra-ui/react";

import defaultPic from "../assets/default-pic.png";
import doc1 from "../assets/documentation/Dokumentasi1.jpeg";
import doc2 from "../assets/documentation/Dokumentasi2.jpeg";
import doc3 from "../assets/news/news3.png";
import { DynamicTitle } from "../utils/DynamicTitle";

const GalleryArr = [doc1, doc2, doc3];

export const GalleryPage = () => {
  DynamicTitle("Documentation | Kapten Mulyono")
  return (
    <Box p={"20px"}>
      <Flex flexWrap={"wrap"} justifyContent={"center"} gap={"20px"}>
        {GalleryArr.map((item, index) => {
          return (
            <Center
              key={index}
              w={"250px"}
              h={"250px"}
              _hover={{ transform: "scale(1.05)" }}
              transition={"0.2s"}
              bg={"black"}
            >
              <PicContainer pic={item} />
            </Center>
          );
        })}
      </Flex>
    </Box>
  );
};

const PicContainer = ({ pic = defaultPic }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box h={"100%"}>
      <Image
        h={"100%"}
        objectFit={"contain"}
        src={pic}
        onClick={onOpen}
        cursor={"pointer"}
      />

      <Modal isOpen={isOpen} onClose={onClose} bg={"red"}>
        <ModalOverlay />
        <ModalContent bg={"none"} boxShadow={"none"}>
          <ModalCloseButton
            pb={"20px"}
            position={"fixed"}
            fontSize={{ base: "1.5rem", md: "2rem" }}
            right={{ base: "20px", md: "50px" }}
            top={{ base: "20px", md: "50px" }}
            color={"white"}
            _hover={{ color: "gray.400" }}
            _active={{ color: "white" }}
          />
          <ModalBody position={"relative"} zIndex={9999} bg={"none"}>
            <Image w={"100%"} src={pic} onClick={onOpen} cursor={"pointer"} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
