import "./App.css";

import { Route, Routes, Navigate } from "react-router-dom";
import { Layout } from "./components/Layout";

// Pages
import { HomePage } from "./pages/HomePage";
import { AboutPage } from "./pages/AboutPage";
import { ComicPage } from "./pages/ComicPage";
import { HistoryPage } from "./pages/HistoryPage";
import { MOHPage } from "./pages/MOHPage";
import { NewsPage } from "./pages/NewsPage";
import { FamilyPage } from "./pages/FamilyPage";
// import { ComicDetailPage } from "./pages/ComicDetailPage";
import { ContactsPage } from "./pages/ContactsPage";
import { PdfPage } from "./pages/PdfPage";
import { GalleryPage } from "./pages/GalleryPage";

function App() {
  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route path={"/tentang-kapten-mulyono"} element={<AboutPage />} />
          <Route path={"/sejarah-kapten-mulyono"} element={<HistoryPage />} />
          <Route path={"/tanda-penghargaan"} element={<MOHPage />} />
          <Route path={"/berita"} element={<NewsPage />} />
          <Route path={"/putra-putri-kapten-mulyono"} element={<FamilyPage />} />
          <Route path={"/komik-kapten-mulyono"} element={<ComicPage />} />
          <Route path={"/kontak"} element={<ContactsPage />} />
          <Route path={"/sumber-kapten-mulyono"} element={<PdfPage />} />
          <Route path={"/dokumentasi-kapten-mulyono"} element={<GalleryPage />} />
        </Route>
        <Route path={"/"} element={<HomePage />} />
        <Route path={"*"} element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default App;
