import { Box, Center, Image } from "@chakra-ui/react";
import family from "../assets/family/family.jpeg";
import { DynamicTitle } from "../utils/DynamicTitle";

export const FamilyPage = () => {
  DynamicTitle("Family | Kapten Mulyono")
  return (
    <Box>
      <Center>
        <Image src={family} maxH={"900px"} border={"1px solid black"} />
      </Center>
    </Box>
  );
};
