import { Box, Center, Flex, Button, Link } from "@chakra-ui/react";

import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { DynamicTitle } from "../utils/DynamicTitle";

export const ContactsPage = () => {
  DynamicTitle("Contacts | Kapten Mulyono")
  return (
    <Box py={"20px"}>
      <Flex gap={"20px"} flexWrap={"wrap"} justifyContent={"center"}>
        {ContactArr.map((item, index) => {
          return (
            <CardComp
              key={index}
              iconPic={item.icon}
              name={item.name}
              contacts={item.contacts}
            />
          );
        })}
      </Flex>
    </Box>
  );
};

const CardComp = ({ name = "NAME", contacts }) => {
  return (
    <Box w={"300px"} h={"150px"} border={"1px solid black"} rounded={"20px"}>
      <Center
        h={"50%"}
        fontSize={"1rem"}
        textAlign={"center"}
        fontWeight={"bold"}
        bg={"#9dff9d"}
        rounded={"20px 20px 0 0"}
      >
        {name}
      </Center>
      <Center
        display={"flex"}
        flexDir={"column"}
        gap={"10px"}
        py={"5px"}
        h={"50%"}
      >
        {contacts?.map((item, index) => {
          const res = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
          const validate = res.test(item.input);
          const hrefValidate = validate
            ? `mailto: ${item.input}?subject=Kapten Mulyono`
            // : `tel: ${item.input}`;
            : `https://wa.me/${(item.input).replace(/\D/g, "")}`;
          return (
            <Button
              as={Link}
              href={hrefValidate}
              key={index}
              leftIcon={validate ? <AiOutlineMail /> : <AiOutlinePhone />}
              colorScheme={"green"}
              variant={"ghost"}
              minW={"270px"}
              target={"_blank"}
            >
              {item.input}
            </Button>
          );
        })}
      </Center>
    </Box>
  );
};

const ContactArr = [
  {
    name: "SATRIA BUDIONO",
    contacts: [
      {
        input: "satria.budiono@gmail.com",
      },
      {
        input: "+62 811-1681-661",
      },
    ],
  },
];
