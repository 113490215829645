import { Box, Link, Text } from "@chakra-ui/react";

export const Footer = () => {
  return (
    <Box
      textAlign={"center"}
      bg={"#3E3E3E"}
      pt={"20px"}
      h={"200px"}
      marginTop={"auto"}
    >
      <Link
        href={"/kontak"}
        color={"white"}
        _hover={{textDecor: "underline" ,textUnderlineOffset: "5px"}}
      >
        CONTACT US
      </Link>
      <Text color={"white"} position={"absolute"} bottom={"10px"} w={"100%"}>© 2023 - Satria Budiono</Text>
    </Box>
  );
};
