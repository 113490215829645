import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
} from "@chakra-ui/react";

import { VscChromeClose, VscMenu, VscChevronDown } from "react-icons/vsc";
import { WebsiteLogo } from "./Logo";
// import { WebsiteLogo2 } from "./Logo2";

export const NavbarComp = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box position={"fixed"} w={"100vw"} zIndex={1000}>
      <Flex
        bg={"whiteAlpha.700"}
        backdropFilter={"blur(5px)"}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={"1px solid #E2E8F0"}
        align={"center"}
      >
        <Flex
          flex={{ base: 1 }}
          justify={"start"}
          h={{ base: "70px", lg: "100px", "2xl": "200px" }}
        >
          <Box
            w={{ base: "300px", lg: "400px", "2xl": "600px" }}
            h={{ lg: "100px", "2xl": "200px" }}
          >
            <Link href={"/"}>
              <WebsiteLogo />
            </Link>
          </Box>
          <Flex display={{ base: "none", lg: "flex" }} ml={"auto"}>
            <DesktopNav />
          </Flex>
        </Flex>
        <Flex
          flex={{ base: 1, lg: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", lg: "none" }}
          justify={"right"}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <VscChromeClose w={3} h={3} /> : <VscMenu w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
};

const DesktopNav = () => {
  return (
    <Flex justify={"right"} w={"100%"} align={"center"}>
      {NAV_ITEMS.map((navItem) => (
        <Box
          key={navItem.label}
          w={{ lg: "120px", "2xl": "170px" }}
          textAlign={"center"}
        >
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                href={navItem.href ?? "#"}
                fontSize={{ lg: "0.7rem", "2xl": "1rem" }}
                fontWeight={500}
                color={"#4a5568"}
                _hover={{
                  textDecoration: "none",
                  color: "red.400",
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={"white"}
                // p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Flex>
  );
};

const DesktopSubNav = ({ label, href }) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: "red.100" }}
      fontSize={{ lg: "0.7rem", "2xl": "1rem" }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "red.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
        </Box>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={"white"}
      p={4}
      display={{ lg: "none" }}
      borderBottom={"1px solid #E2E8F0"}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
          fontWeight: "600",
          color: "red.400",
        }}
        color={"#4a5568"}
      >
        <Text>{label}</Text>
        {children && (
          <Icon
            as={VscChevronDown}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={"1px solid grey"}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link
                key={child.label}
                py={2}
                href={child.href}
                _hover={{ fontWeight: "600", color: "red.400" }}
              >
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "HOME",
    href: "/",
  },
  {
    label: "TENTANG KAPTEN MULYONO",
    href: "/tentang-kapten-mulyono",
  },
  {
    label: "SEJARAH KAPTEN MULYONO",
    href: "/sejarah-kapten-mulyono",
  },
  {
    label: "KOMIK KAPTEN MULYONO",
    href: "/komik-kapten-mulyono",
  },
  {
    label: "TANDA PENGHARGAAN & LAINNYA",
    children: [
      {
        label: "TANDA PENGHARGAAN",
        href: "/tanda-penghargaan",
      },
      {
        label: "BERITA",
        href: "/berita",
      },
      {
        label: "PUTRA-PUTRI KAPTEN MULYONO",
        href: "/putra-putri-kapten-mulyono",
      },
      {
        label: "SUMBER",
        href: "/sumber-kapten-mulyono",
      },
      {
        label: "DOKUMENTASI",
        href: "/dokumentasi-kapten-mulyono",
      },
    ],
  },
];
