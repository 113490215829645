import { Box, Center, Image, Stack } from "@chakra-ui/react";

import MOH1 from "../assets/moh/MOH1.jpg";
import MOH2 from "../assets/moh/MOH2.jpg";
import MOH3 from "../assets/moh/MOH3.jpg";
import MOH4 from "../assets/moh/MOH4.jpg";
import MOH5 from "../assets/moh/MOH5.jpg";
import { DynamicTitle } from "../utils/DynamicTitle";

const MOHPic = [MOH1, MOH2, MOH3, MOH4, MOH5];

export const MOHPage = () => {
  DynamicTitle("Medal of Honor | Kapten Mulyono")
  
  return (
    <Box>
      <Center>
        <Stack>
          {MOHPic.map((item, index) => {
            return (
              <Box key={index}>
                <Image src={item} border={"1px solid black"} />
              </Box>
            );
          })}
        </Stack>
      </Center>
    </Box>
  );
};
